// CheckoutSuccess.js
import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const CheckoutSuccess = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const sessionId = params.get('session_id');

    if (!sessionId) {
      navigate('/pricing');
      return;
    }

    // Optionally, you can display a success message or fetch session details

    // Redirect to dashboard or appropriate page
    navigate('/keywords');
  }, [location, navigate]);

  return (
    <div className="container mx-auto py-24 px-6">
      <h2 className="text-3xl font-semibold mb-4">Subscription Successful!</h2>
      <p>Your subscription is now active. Thank you for subscribing!</p>
    </div>
  );
};

export default CheckoutSuccess;
