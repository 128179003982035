// Import necessary modules
import React, { useState, useEffect, useRef } from 'react';
import {
  searchKeywords,
  createBlogPost,
  fetchSavedKeywords,
} from '../services/api';
import { useNavigate, useLocation } from 'react-router-dom';
import Select from 'react-select';
import moment from 'moment';
import Menu from '../components/Menu';
import Footer from '../components/Footer';
import axiosInstance from '../services/axiosInstance';
import Modal from '../components/Modal';

import { Tooltip as ReactTooltip } from 'react-tooltip';
import { FaInfoCircle } from 'react-icons/fa'; // Import an info icon
import 'react-tooltip/dist/react-tooltip.css';

// **Import the country and language options JSON files**
import countryOptions from '../data/countryOptions'; // Adjust the path if necessary
import languageOptions from '../data/languageOptions'; // Adjust the path if necessary

const KeywordList = () => {
  const [keywords, setKeywords] = useState([]);
  const [loadingSavedKeywords, setLoadingSavedKeywords] = useState(false); // For loading saved keywords
  const [creatingPost, setCreatingPost] = useState(false);
  const [showLoadingModal, setShowLoadingModal] = useState(false); // For blog post creation
  const [showSearchModal, setShowSearchModal] = useState(false); // For keyword search
  const [showTrialLimitModal, setShowTrialLimitModal] = useState(false); // For trial limit modal
  const [modalMessage, setModalMessage] = useState(''); // State to manage modal message
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedCountry, setSelectedCountry] = useState(null); // Updated initial state
  const [selectedLanguage, setSelectedLanguage] = useState(null); // New state for language selection
  const [searching, setSearching] = useState(false);
  const navigate = useNavigate();
  const location = useLocation(); // Get location state

  const hasPerformedInitialSearch = useRef(false); // Ref to prevent duplicate searches

  // Check if onboarding is complete
  useEffect(() => {
    const checkOnboardingStatus = async () => {
      const token = localStorage.getItem('token');
      if (!token) {
        navigate('/');
        return;
      }

      try {
        const response = await axiosInstance.get('/user'); // Assume this endpoint checks onboarding status
        if (!response.data.onboardingComplete) {
          navigate('/onboarding');
        }
      } catch (error) {
        console.error('Error checking onboarding status:', error);
        navigate('/');
      }
    };

    checkOnboardingStatus();
  }, [navigate]);

  // Fetch all keywords from MongoDB
  const fetchAllKeywords = async () => {
    setLoadingSavedKeywords(true); // Start loading saved keywords
    try {
      const savedResult = await fetchSavedKeywords();
      setKeywords(savedResult.data);
    } catch (error) {
      console.error('Error fetching keywords:', error);
    } finally {
      setLoadingSavedKeywords(false); // Stop loading saved keywords
    }
  };

  // Handle component mount and routing
  useEffect(() => {
    if (!hasPerformedInitialSearch.current) {
      if (location.state && location.state.searchQuery) {
        // If a searchQuery is provided via location state, perform automatic search
        const initialQuery = location.state.searchQuery;
        setSearchQuery(initialQuery);
  
        // Set the selected country and language from location state
        if (location.state.country) {
          setSelectedCountry(location.state.country);
        }
        if (location.state.language) {
          setSelectedLanguage(location.state.language);
        }
  
        // Perform the search with the initial query, passing country and language
        handleSearch(initialQuery, location.state.country, location.state.language);
      } else {
        // Otherwise, fetch saved keywords
        fetchAllKeywords();
      }
      hasPerformedInitialSearch.current = true; // Set to true after first execution
    }
  }, [location.state]);  

  // Frontend logic for handling keyword search
  const handleSearch = async (query, country, language) => {
    // Ensure both country and language are selected
    if (!query || !country || !language) {
      setModalMessage('Please enter a keyword, and select both a country and language.');
      setShowTrialLimitModal(true);
      return;
    }

    setSearching(true);
    setShowSearchModal(true); // Show the search modal

    try {
      // Pass country and language codes to the API
      const response = await searchKeywords(
        query,
        country.value, // country_code
        language.value, // language_code
        country.label,  // country name
        language.label  // language name
      );

      // Check if the response contains the redirect flag
      if (response.redirect_to_profile) {
        setModalMessage(
          'You have reached your trial limit for keyword searches. Please go to your profile to upgrade your subscription.'
        );
        setShowTrialLimitModal(true); // Show the modal when the trial limit is reached
      } else {
        await fetchAllKeywords(); // Fetch the new keywords
      }
      setSearching(false);
      setShowSearchModal(false); // Hide the search modal when done
    } catch (error) {
      console.error('Error searching keywords:', error);
      setSearching(false);
      setShowSearchModal(false); // Hide the search modal on error
      setModalMessage(
        'An error occurred while searching for keywords. Please try again.'
      );
      setShowTrialLimitModal(true); // Show modal with error message
    }
  };

  // Helper function to map difficulty score to a label
  const getDifficultyLabel = (score) => {
    if (score === null || score === undefined) {
      return { label: 'N/A', className: 'text-gray-500' };
    }
    if (score <= 29) {
      return { label: 'Easy', className: 'text-green-600' };
    } else if (score <= 59) {
      return { label: 'Medium', className: 'text-yellow-600' };
    } else if (score <= 79) {
      return { label: 'Hard', className: 'text-orange-600' };
    } else {
      return { label: 'Very Hard', className: 'text-red-600' };
    }
  };

  // Helper function to format search volume
  const formatSearchVolume = (volume) => {
    if (volume === null || volume === undefined) {
      return 'N/A';
    }
    if (volume < 10) {
      return '< 10';
    } else {
      return volume;
    }
  };

  // Handle creating a blog post
  const handleCreatePost = async (keywordId) => {
    setCreatingPost(true); // Show loading state
    setShowLoadingModal(true); // Show the loading modal
    setShowTrialLimitModal(false); // Ensure the trial limit modal is hidden
  
    try {
      // Pass keywordId to the API
      const response = await createBlogPost(keywordId);
  
      // Log the response for debugging
      console.log('Response from createBlogPost:', response);
  
      // Check if the trial limit is reached
      if (response.redirect_to_profile) {
        setCreatingPost(false); // Stop loading state
        setShowLoadingModal(false); // Hide loading modal
        setModalMessage(
          'You have reached your trial limit for blog posts. Please go to your profile to upgrade your subscription.'
        );
        setShowTrialLimitModal(true); // Show trial limit modal
        return;
      }
  
      // Check if the blog post was successfully created and has a post ID
      if (response.post_id) {
        setCreatingPost(false); // Stop loading state
        setShowLoadingModal(false); // Hide loading modal
        navigate(`/blogpost/${response.post_id}`); // Redirect to the created blog post
      } else {
        // If no post ID is returned, handle the error
        console.error('Error: No post ID returned from the API');
        setCreatingPost(false);
        setShowLoadingModal(false); // Hide loading modal
        setModalMessage('Failed to create blog post. Please try again.');
        setShowTrialLimitModal(true); // Show error modal
      }
    } catch (error) {
      console.error('Error creating blog post:', error);
      setCreatingPost(false);
      setShowLoadingModal(false); // Hide loading modal
      setModalMessage(
        'An error occurred while creating the blog post. Please try again.'
      );
      setShowTrialLimitModal(true); // Show modal with error message
    }
  };

  // If loading saved keywords, show the loading indicator
  if (loadingSavedKeywords)
    return (
      <>
        <Menu />
        <div className="flex items-center justify-center min-h-screen bg-white">
          <div className="flex flex-col items-center">
            <div className="loader mb-4"></div>
            <p className="text-xl text-gray-700">Loading keywords...</p>
          </div>
        </div>
        <Footer />
      </>
    );

  // Sort keywords by 'createdAt' descending, then by 'avgMonthlySearches' descending
  const sortedKeywords = keywords.sort((a, b) => {
    const dateA = new Date(a.createdAt || 0);
    const dateB = new Date(b.createdAt || 0);

    // First, compare the dates
    if (dateB - dateA !== 0) {
      return dateB - dateA; // Sort by date descending
    }

    // If dates are equal, sort by search volume descending
    const volumeA = a.avgMonthlySearches || 0;
    const volumeB = b.avgMonthlySearches || 0;

    return volumeB - volumeA; // Sort by search volume descending
  });

  return (
    <>
      <Menu />
      <div className="container mx-auto py-24 px-6 bg-white">
        <h2 className="text-5xl font-semibold mb-16 text-center text-gray-900">
          Keyword Ideas
        </h2>

        {/* Search Bar, Country, and Language Selector */}
        <div className="mb-16 flex flex-col md:flex-row items-center justify-center space-y-6 md:space-y-0 md:space-x-6">
          <div className="relative w-full max-w-lg">
            <input
              type="text"
              placeholder="Type a topic or niche to discover keywords"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="border border-gray-300 w-full px-4 pr-12 rounded-md focus:outline-none focus:border-gray-500 text-base h-10"
            />
          </div>
          <div className="relative w-full max-w-xs">
            <Select
              options={countryOptions}
              value={selectedCountry}
              onChange={setSelectedCountry}
              placeholder="Select a Country"
              classNamePrefix="react-select"
            />
          </div>
          <div className="relative w-full max-w-xs">
            <Select
              options={languageOptions}
              value={selectedLanguage}
              onChange={setSelectedLanguage}
              placeholder="Select a Language"
              classNamePrefix="react-select"
            />
          </div>
          <button
            onClick={() => handleSearch(searchQuery, selectedCountry, selectedLanguage)}
            disabled={searching}
            className="bg-black text-white px-6 rounded-md text-base font-medium hover:bg-gray-800 transition focus:outline-none h-10 flex items-center justify-center"
          >
            {searching ? 'Searching...' : 'Search'}
          </button>
        </div>

        <ReactTooltip
          id="searches-per-month-tooltip"
          place="top"
          effect="solid"
          multiline={true}
          render={() => (
            <span>
              The average number of times people search for this keyword per month.<br />
              If you're a new website, focus on keywords with 0-500 monthly volume search.<br />
              Long-tail keywords may have lower search volumes but can be less competitive and more targeted.
            </span>
          )}
        />

        <ReactTooltip
          id="difficulty-score-tooltip"
          place="top"
          effect="solid"
          multiline={true}
          render={() => (
            <span>
              An estimate of how hard it is to rank for this keyword.<br />
              If you're a new website without domain authority, focus on 'Easy' or 'Medium' difficulty keywords to improve your chances of ranking.
            </span>
          )}
        />

        {/* Keywords Table */}
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white">
            <thead>
              <tr>
                <th className="px-6 py-3 text-left text-sm font-semibold text-gray-900">
                  Search Date
                </th>
                <th className="px-6 py-3 text-left text-sm font-semibold text-gray-900">
                  Keyword
                </th>
                <th className="px-6 py-3 text-left text-sm font-semibold text-gray-900">
                  Searches/Month
                  <FaInfoCircle
                    data-tooltip-id="searches-per-month-tooltip"
                    className="inline ml-1 text-gray-500 cursor-pointer"
                  />
                </th>
                <th className="px-6 py-3 text-left text-sm font-semibold text-gray-900">
                  Difficulty Score
                  <FaInfoCircle
                    data-tooltip-id="difficulty-score-tooltip"
                    className="inline ml-1 text-gray-500 cursor-pointer"
                  />
                </th>
                <th className="px-6 py-3 text-center text-sm font-semibold text-gray-900">
                  Action
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              {sortedKeywords.map((keyword, index) => (
                <tr key={index} className="hover:bg-gray-50">
                  <td className="px-6 py-4 text-sm text-gray-500">
                    {keyword.createdAt
                      ? moment(keyword.createdAt).format('MMM Do, YYYY')
                      : 'N/A'}
                  </td>
                  <td className="px-6 py-4 text-sm font-medium text-gray-900">
                    {keyword.keyword}
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-500">
                    {formatSearchVolume(keyword.avgMonthlySearches)}
                  </td>
                  <td className="px-6 py-4 text-sm">
                    <span className={getDifficultyLabel(keyword.difficultyScore).className}>
                      {getDifficultyLabel(keyword.difficultyScore).label}
                    </span>
                  </td>
                  <td className="px-6 py-4 text-center">
                    <button
                      onClick={() => handleCreatePost(keyword._id)}
                      disabled={creatingPost}
                      className="bg-black text-white py-2 px-4 rounded-md text-sm font-medium hover:bg-gray-800 transition focus:outline-none"
                    >
                      {creatingPost ? 'Creating...' : 'Create Blog Post'}
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* Search Loading Modal */}
        {showSearchModal && (
          <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-white rounded-md shadow-lg p-8 max-w-md w-full">
              <h3 className="text-xl font-semibold text-gray-900 mb-4 text-center">
                Analyzing Keyword...
              </h3>
              <div className="text-gray-700 mb-6 text-center space-y-4">
                <p>
                  We're analyzing your keyword and gathering relevant data.
                </p>
                <p>
                  Our AI is generating valuable long-tail keyword suggestions by examining search volumes and competition.
                </p>
                <p>
                  Hang tight, this may take just a few moments.
                </p>
              </div>
              <div className="flex justify-center">
                <div className="loader"></div>
              </div>
            </div>
          </div>
        )}

        {/* Blog Post Creation Loading Modal */}
        {showLoadingModal && (
          <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-white rounded-md shadow-lg p-8 max-w-sm w-full">
              <h3 className="text-xl font-semibold text-gray-900 mb-6 text-center">
                Creating Blog Post...
              </h3>
              <div className="flex justify-center">
                <div className="loader"></div>
              </div>
            </div>
          </div>
        )}

        {/* Trial Limit Modal */}
        {showTrialLimitModal && (
          <Modal
            message={modalMessage}
            onClose={() => setShowTrialLimitModal(false)}
            onAction={() => navigate('/profile')}
            actionText="Go to Profile"
          />
        )}
      </div>
      <Footer />
    </>
  );
};

export default KeywordList;