import React, { createContext, useEffect, useState } from 'react';
import { jwtDecode } from 'jwt-decode';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(undefined);
  const [userId, setUserId] = useState(null);
  const [onboardingComplete, setOnboardingComplete] = useState(null);

  const decodeToken = (token) => {
    try {
      const decoded = jwtDecode(token);
      console.log('Decoded token in AuthContext:', decoded);
      setUserId(decoded.userId);
      setOnboardingComplete(decoded.onboardingComplete);
    } catch (error) {
      console.error('Error decoding token:', error);
      setToken(null);
      localStorage.removeItem('token');
    }
  };

  useEffect(() => {
    const storedToken = localStorage.getItem('token');
    if (storedToken) {
      setToken(storedToken);
      decodeToken(storedToken);
    } else {
      setToken(null);
    }
  }, []);

  const login = (newToken) => {
    console.log('Login called with token:', newToken);
    setToken(newToken);
    localStorage.setItem('token', newToken);
    decodeToken(newToken);
  };

  // No navigation logic here

  return (
    <AuthContext.Provider value={{ token, userId, onboardingComplete, login }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
