// src/components/HomeMenu.js
import React from 'react';
import { Link } from 'react-router-dom';

const HomeMenu = () => {
  return (
    <nav className="bg-black py-4">
      <div className="container mx-auto px-6 lg:px-12 flex justify-between items-center">
        {/* Logo */}
        <Link
          to="/"
          className="text-2xl font-bold text-white"
          style={{ fontFamily: 'Poppins, sans-serif' }}
        >
          RANQIO
        </Link>

        {/* Navigation Links */}
        <div className="flex space-x-6 flex-nowrap">
          {/* Blog Link as an <a> Tag */}
          <a
            href="/blog/"
            className="font-medium text-white hover:text-gray-300 px-4 py-3 flex items-center transition duration-150 ease-in-out no-underline"
            style={{ fontFamily: 'Poppins, sans-serif' }}
          >
            Blog
          </a>

          {/* Sign In Link */}
          <Link
            to="/signin"
            className="font-medium text-white hover:text-gray-300 px-4 py-3 flex items-center transition duration-150 ease-in-out no-underline"
            style={{ fontFamily: 'Poppins, sans-serif' }}
          >
            Sign In
          </Link>

          {/* Try Free Link Styled as a Button */}
          <Link
            to="/signup"
            className="font-medium bg-gray-700 text-white hover:bg-gray-600 px-4 py-3 flex items-center transition duration-150 ease-in-out rounded no-underline"
            style={{ fontFamily: 'Poppins, sans-serif' }}
          >
            Try Free
          </Link>
        </div>
      </div>
    </nav>
  );
};

export default HomeMenu;