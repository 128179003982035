// src/pages/ProfilePage.js

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment';
import { Link, useNavigate } from 'react-router-dom'; // Updated for React Router v6
import { FaCalendarAlt, FaCreditCard, FaTimes } from 'react-icons/fa';
import Menu from '../components/Menu';
import Footer from '../components/Footer';
import axiosInstance from '../services/axiosInstance';

const Profile = () => {
  const [userData, setUserData] = useState(null); // Holds user and subscription data
  const [isLoading, setIsLoading] = useState(true); // Loading state
  const [error, setError] = useState(null); // Error state
  const navigate = useNavigate(); // For navigation

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const token = localStorage.getItem('token'); // Adjust based on your auth implementation
        if (!token) {
          throw new Error('No authentication token found. Please log in.');
        }
        const response = await axiosInstance.get('/user', { // Updated endpoint
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setUserData(response.data);
      } catch (err) {
        console.error('Error fetching user profile:', err);
        setError(err.response?.data?.message || 'Failed to load profile data.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchUserProfile();
  }, []);

  // Logout Handler
  const handleLogout = () => {
    localStorage.removeItem('token'); // Adjust based on your auth implementation
    navigate('/signin'); // Redirect to login page
  };

  // Subscribe Handler
  const handleSubscribe = async () => {
    navigate('/pricing');
  };

  // Cancel Subscription Handler
  const handleCancelSubscription = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('No authentication token found.');
      }
      const response = await axiosInstance.post(
        '/cancel-subscription', // Updated endpoint
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      alert(response.data.message || 'Subscription canceled successfully.');
      // Refresh user data after cancellation
      const refreshedResponse = await axiosInstance.get('/user', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setUserData(refreshedResponse.data);
    } catch (err) {
      console.error('Error canceling subscription:', err);
      alert(err.response?.data?.message || 'Failed to cancel subscription. Please try again.');
    }
  };

  // Redirect to Stripe Customer Portal
  const handleManagePayment = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('No authentication token found.');
      }
      const response = await axiosInstance.get('/customer-portal', { // Updated endpoint
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const { url } = response.data;
      window.location.href = url; // Redirect to Customer Portal
    } catch (err) {
      console.error('Error accessing customer portal:', err);
      alert(err.response?.data?.message || 'Failed to access payment methods. Please try again.');
    }
  };

  if (isLoading) {
    return (
      <>
        <Menu />
        <div className="flex items-center justify-center min-h-screen bg-gray-100">
          <div className="flex flex-col items-center">
            <div className="loader mb-4"></div> {/* Ensure .loader is defined in your CSS */}
            <p className="text-lg text-gray-700">Loading profile...</p>
          </div>
        </div>
        <Footer />
      </>
    );
  }

  if (error) {
    return (
      <>
        <Menu />
        <div className="flex items-center justify-center min-h-screen bg-gray-100">
          <p className="text-lg text-red-500">{error}</p>
        </div>
        <Footer />
      </>
    );
  }

  if (!userData) {
    return null; // Or a fallback UI
  }

  const {
    name,
    email,
    subscriptionStatus,
    keywordSearchCount,
    blogPostCount,
    subscriptionAmount,
    subscriptionRenewalDate,
  } = userData;

  const isTrial = subscriptionStatus === 'trial';
  const isPremium = subscriptionStatus === 'active'; // Adjust based on your backend status

  // Determine remaining usage
  const maxKeywordSearch = isTrial ? 10 : 500; // Example limits
  const maxBlogPosts = isTrial ? 5 : 100; // Example limits

  return (
    <>
      <Menu />
      <div className="container mx-auto py-16 px-6 min-h-screen">
        <h2 className="text-3xl font-semibold mb-12 text-center text-gray-900">
          Profile
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {/* User Data Block */}
          <div className="bg-white shadow-md rounded-lg p-6">
            <h3 className="text-2xl font-bold mb-4 text-gray-800">User Information</h3>
            <div className="mb-4">
              <label className="block text-gray-600 mb-2">Name</label>
              <p className="text-lg text-gray-800">{name}</p>
            </div>
            <div>
              <label className="block text-gray-600 mb-2">Email</label>
              <p className="text-lg text-gray-800">{email}</p>
            </div>
          </div>

          {/* Account Data Block */}
          <div className="bg-white shadow-md rounded-lg p-6">
            <h3 className="text-2xl font-bold mb-4 text-gray-800">Account Information</h3>
            <div className="mb-4">
              <label className="block text-gray-600 mb-2">Subscription Type</label>
              <p className={`text-lg font-medium ${isPremium ? 'text-green-600' : 'text-blue-600'}`}>
                {isPremium ? 'Premium' : 'Trial'}
              </p>
            </div>
            <div className="mb-4">
              <label className="block text-gray-600 mb-2">Keyword Searches Left</label>
              <p className="text-lg text-gray-800">{keywordSearchCount} / {maxKeywordSearch}</p>
            </div>
            <div className="mb-6">
              <label className="block text-gray-600 mb-2">Blog Posts Left</label>
              <p className="text-lg text-gray-800">{blogPostCount} / {maxBlogPosts}</p>
            </div>

            {/* Trial Users */}
            {isTrial && (keywordSearchCount >= maxKeywordSearch || blogPostCount >= maxBlogPosts) && (
              <div className="mb-6">
                <p className="text-gray-700 mb-4">
                  You've reached your trial limits. Upgrade to Premium to continue enjoying Ranqio's benefits!
                </p>
                <button
                  onClick={handleSubscribe}
                  className="w-full bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 transition"
                >
                  Upgrade to Premium
                </button>
              </div>
            )}

            {/* Premium Users */}
            {isPremium && (
              <>
                <div className="mb-4">
                  <label className="block text-gray-600 mb-2">Subscription Amount</label>
                  <p className="text-lg text-gray-800">${subscriptionAmount}/monthly</p>
                </div>
                <div className="mb-6">
                  <label className="block text-gray-600 mb-2">Renewal Date</label>
                  <p className="text-lg text-gray-800">
                    {subscriptionRenewalDate ? moment(subscriptionRenewalDate).format('MMMM Do, YYYY') : 'N/A'}
                  </p>
                </div>
                <div className="flex flex-col space-y-4">
                  <button
                    onClick={handleManagePayment}
                    className="flex items-center justify-center bg-gray-200 text-gray-800 py-2 px-4 rounded hover:bg-gray-300 transition"
                  >
                    <FaCreditCard className="mr-2" />
                    Manage Payment Methods
                  </button>
                  <button
                    onClick={handleCancelSubscription}
                    className="flex items-center justify-center bg-red-500 text-white py-2 px-4 rounded hover:bg-red-600 transition"
                  >
                    <FaTimes className="mr-2" />
                    Cancel Subscription
                  </button>
                </div>
              </>
            )}
          </div>
        </div>

        {/* Logout Button */}
        <div className="flex justify-center mt-12">
          <button
            onClick={handleLogout}
            className="bg-red-600 text-white py-2 px-6 rounded hover:bg-red-700 transition"
          >
            Logout
          </button>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Profile;