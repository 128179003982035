import axiosInstance from './axiosInstance';

// Function to search keywords
export const searchKeywords = async (
  query,
  countryCode,
  languageCode,
  countryName,
  languageName
) => {
  try {
    const response = await axiosInstance.post('/search', {
      query,
      countryCode,
      languageCode,
      countryName,
      languageName,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Function to create a blog post and return the post ID
export const createBlogPost = async (keywordId) => {
  try {
    const response = await axiosInstance.post('/creator', {
      keywordId,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Function to fetch blog posts (filtered by userId in the backend)
export const fetchBlogPosts = async () => {
  const response = await axiosInstance.get('/blogposts');
  return response.data;
};

// Function to fetch a specific blog post by ID
export const fetchBlogPostById = async (id) => {
  const response = await axiosInstance.get(`/blogpost/${id}`);
  return response.data;
};

// Add the function to fetch saved keywords (filtered by userId in the backend)
export const fetchSavedKeywords = async () => {
  const response = await axiosInstance.get('/saved-keywords');
  return response.data;
};
