import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../services/axiosInstance';
import Menu from '../components/Menu';
import Footer from '../components/Footer';
import Select from 'react-select';

// Import the same country and language options
import countryOptions from '../data/countryOptions';
import languageOptions from '../data/languageOptions';

const Onboarding = () => {
  const [formData, setFormData] = useState({
    website: '',
    marketSegment: '',
    productService: '',
    country: '', // Added country field
    language: '', // Added language field
  });

  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedLanguage, setSelectedLanguage] = useState(null);

  const navigate = useNavigate();

  // Handle input change for text fields
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  // Handle country change for dropdown
  const handleCountryChange = (selectedOption) => {
    setSelectedCountry(selectedOption);
    setFormData({
      ...formData,
      country: selectedOption ? selectedOption.value : '', // Handle null case
    });
  };

  // Handle language change for dropdown
  const handleLanguageChange = (selectedOption) => {
    setSelectedLanguage(selectedOption);
    setFormData({
      ...formData,
      language: selectedOption ? selectedOption.value : '', // Handle null case
    });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Send onboarding data to the backend
      await axiosInstance.post('/onboarding', {
        website: formData.website,
        marketSegment: formData.marketSegment,
        productService: formData.productService,
        country: formData.country, // Send the selected country
        language: formData.language, // Send the selected language
      });

      // Prepare the search query
      const searchQuery = `${formData.productService}`;

      // Navigate to /keywords and pass the search query, country, and language via state
      navigate('/keywords', {
        state: {
          searchQuery,
          country: selectedCountry,
          language: selectedLanguage,
        },
      });
    } catch (error) {
      console.error('Error saving onboarding data:', error);
      // Handle error (e.g., display a message to the user)
    }
  };

  return (
    <>
      <Menu />
      <div className="container mx-auto py-24 px-6 bg-white">
        <h2 className="text-5xl font-semibold mb-16 text-center text-gray-900">
          Tell Us About Your Project
        </h2>
        <form onSubmit={handleSubmit} className="max-w-md mx-auto">
          {/* Website Field */}
          <div className="mb-6">
            <label className="block text-gray-700 mb-2">Website</label>
            <input
              type="url"
              name="website"
              value={formData.website}
              onChange={handleChange}
              className="w-full border border-gray-300 px-4 py-3 rounded-md focus:outline-none focus:border-gray-500"
              placeholder="https://yourwebsite.com"
            />
          </div>

          {/* Market Segment Field */}
          <div className="mb-6">
            <label className="block text-gray-700 mb-2">Ideal Customer</label>
            <input
              type="text"
              name="marketSegment"
              value={formData.marketSegment}
              onChange={handleChange}
              className="w-full border border-gray-300 px-4 py-3 rounded-md focus:outline-none focus:border-gray-500"
              placeholder="e.g., tech-savvy millennials, small business owners, new parents"
              required
            />
          </div>

          {/* Product/Service Field */}
          <div className="mb-6">
            <label className="block text-gray-700 mb-2">Product/Service</label>
            <input
              type="text"
              name="productService"
              value={formData.productService}
              onChange={handleChange}
              className="w-full border border-gray-300 px-4 py-3 rounded-md focus:outline-none focus:border-gray-500"
              placeholder="e.g., Mobile App Development, Personal Training"
              required
            />
          </div>

          {/* Country Dropdown */}
          <div className="mb-6">
            <label className="block text-gray-700 mb-2">Country</label>
            <Select
              options={countryOptions}
              value={selectedCountry}
              onChange={handleCountryChange}
              placeholder="Select a Country"
              classNamePrefix="react-select"
            />
          </div>

          {/* Language Dropdown */}
          <div className="mb-8">
            <label className="block text-gray-700 mb-2">Language</label>
            <Select
              options={languageOptions}
              value={selectedLanguage}
              onChange={handleLanguageChange}
              placeholder="Select a Language"
              classNamePrefix="react-select"
            />
          </div>

          {/* Submit Button */}
          <button
            type="submit"
            className="w-full bg-black text-white py-3 rounded-md text-lg font-medium hover:bg-gray-800 transition focus:outline-none"
          >
            Continue
          </button>
        </form>
      </div>
      <Footer />
    </>
  );
};

export default Onboarding;