// SignUp.js

import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Footer from '../components/Footer';
import AuthContext from '../contexts/AuthContext';
import { FaGoogle } from 'react-icons/fa'; // Import Google icon 
import axiosInstance from '../services/axiosInstance';

const SignUp = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
  });

  const [message, setMessage] = useState('');
  const [success, setSuccess] = useState(false);

  const navigate = useNavigate();
  const { login } = useContext(AuthContext);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axiosInstance.post('/signup', formData);
      const { token } = response.data;

      login(token);

      setMessage(response.data.message);
      setSuccess(true);

      // Redirect to /onboarding page
      navigate('/onboarding');
    } catch (error) {
      if (error.response) {
        setMessage(error.response.data.message);
      } else {
        setMessage('An error occurred.');
      }
      setSuccess(false);
    }
  };

  return (
    <>
      <div className="container mx-auto py-24 px-6 bg-white">
        <h2 className="text-5xl font-semibold mb-16 text-center text-gray-900">
          Let's Start Your Journey to Organic Growth
        </h2>
        <form onSubmit={handleSubmit} className="max-w-md mx-auto">
          {message && (
            <div
              className={`mb-4 text-center ${
                success ? 'text-green-500' : 'text-red-500'
              }`}
            >
              {message}
            </div>
          )}
          <div className="mb-6">
            <label className="block text-gray-700 mb-2">Name</label>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              className="w-full border border-gray-300 px-4 py-3 rounded-md focus:outline-none focus:border-gray-500"
              required
            />
          </div>
          <div className="mb-6">
            <label className="block text-gray-700 mb-2">Email</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className="w-full border border-gray-300 px-4 py-3 rounded-md focus:outline-none focus:border-gray-500"
              required
            />
          </div>
          <div className="mb-8">
            <label className="block text-gray-700 mb-2">Password</label>
            <input
              type="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              className="w-full border border-gray-300 px-4 py-3 rounded-md focus:outline-none focus:border-gray-500"
              required
            />
          </div>
          <button
            type="submit"
            className="w-full bg-black text-white py-3 rounded-md text-lg font-medium hover:bg-gray-800 transition focus:outline-none"
          >
            Sign Up
          </button>

          {/* Google Sign-Up Button */}
          <div className="mt-8 flex justify-center">
            <a
              href="https://ranqio.com/api/auth/google"
              className="flex items-center justify-center bg-white text-black py-2 px-4 border border-gray-300 rounded-md text-lg hover:bg-gray-100 transition focus:outline-none"
            >
              <FaGoogle className="mr-2" /> {/* Google Icon */}
              Continue with Google
            </a>
          </div>
        </form>
      </div>
      <Footer />
    </>
  );
};

export default SignUp;
