import React, { useEffect, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import AuthContext from '../contexts/AuthContext';
import { jwtDecode } from 'jwt-decode';

const OAuthCallbackHandler = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { login } = useContext(AuthContext);

  useEffect(() => {
    console.log('OAuthCallbackHandler rendered');
    const params = new URLSearchParams(location.search);
    const token = params.get('token');

    console.log('Token from query params:', token);

    if (token) {
      login(token);
      // Decode token to get onboarding status
      const decoded = jwtDecode(token);
      const onboardingComplete = decoded.onboardingComplete;

      console.log('Decoded token:', decoded);
      console.log('Onboarding Complete:', onboardingComplete);

      // Redirect based on onboarding completion
      if (onboardingComplete) {
        navigate('/keywords');
      } else {
        navigate('/onboarding');
      }
    } else {
      console.error('No token found in query parameters');
      navigate('/signin');
    }
  }, [location, login, navigate]);

  return null;
};

export default OAuthCallbackHandler;
