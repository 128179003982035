// axiosInstance.js

import axios from 'axios';

const axiosInstance = axios.create({
  //baseURL: 'http://localhost:4000', // Your backend URL locally
  baseURL: '/api', // Your backend URL deployed
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token'); // Get token from localStorage
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`; // Attach token
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 403) {
      // Token is expired or user is not authorized
      localStorage.removeItem('token'); // Clear the token
      window.location.href = '/signin'; // Redirect to login page
    }
    return Promise.reject(error);
  }
);


export default axiosInstance;
