// usePageTracking.js
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga';

const usePageTracking = () => {
  const location = useLocation();

  useEffect(() => {
    const pagePath = location.pathname + location.search;
    console.log('ReactGA tracking page view:', pagePath);
    ReactGA.pageview(pagePath);
  }, [location]);
};

export default usePageTracking;