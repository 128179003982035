// Pricing.js
import React, { useContext } from 'react';
import axiosInstance from '../services/axiosInstance';
import { loadStripe } from '@stripe/stripe-js';
import AuthContext from '../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';

// Replace with your Stripe live publishable key
const stripePromise = loadStripe('pk_live_51Q2M2FB03OTgBCZpMCDIpxyRb8HUSeqnljFsWHJZnWGROTI5lZIVR1KyjyL4ITYOXkbJoD0Um4UZNiDTvVPiogpg00jmfJMguU'); 

const Pricing = () => {
  const { token } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleSubscribe = async (priceId) => {
    if (!token) {
      navigate('/signin');
      return;
    }

    const stripe = await stripePromise;

    try {
      const response = await axiosInstance.post('/create-checkout-session', {
        priceId: priceId,
      });

      const { sessionId } = response.data;

      // Redirect to Stripe Checkout
      const { error } = await stripe.redirectToCheckout({ sessionId });
      if (error) {
        console.error('Stripe redirect error:', error);
      }
    } catch (error) {
      console.error('Error creating checkout session:', error);
    }
  };

  // Define the pricing options
  const pricingOptions = [
    {
      title: 'Premium Monthly',
      description: '- 500 Keyword Searches\n- 100 Blog Post Generations',
      price: '$24.90/month',
      priceId: 'price_1QGQ8XB03OTgBCZp4GZbc7qN', // Monthly Price ID
    },
    {
      title: 'Premium Every 3 Months',
      description: '- 500 Keyword Searches\n- 100 Blog Post Generations',
      price: '$67.90/3 months',
      priceId: 'price_1QGQ8XB03OTgBCZpFuvXWNmx', // Every 3 Months Price ID
    },
    {
      title: 'Premium Yearly',
      description: '- 500 Keyword Searches\n- 100 Blog Post Generations',
      price: '$239.90/year',
      priceId: 'price_1QGQ8XB03OTgBCZp7Qj1KnkL', // Yearly Price ID
    },
  ];

  return (
    <div className="container mx-auto py-24 px-6">
      <h2 className="text-5xl font-semibold mb-16 text-center text-gray-900">
        Choose Your Plan
      </h2>
      <div className="flex flex-col md:flex-row justify-center items-center">
        {/* Trial Plan */}
        <div className="max-w-sm rounded overflow-hidden shadow-lg m-4">
          <div className="px-6 py-4">
            <div className="font-bold text-xl mb-2">Trial Plan</div>
            <p className="text-gray-700 text-base">
              - 10 Keyword Searches<br />
              - 5 Blog Post Generations<br />
            </p>
            <p className="text-gray-700 text-base">
              Free
            </p>
          </div>
        </div>

        {/* Premium Plans */}
        {pricingOptions.map((plan, index) => (
          <div key={index} className="max-w-sm rounded overflow-hidden shadow-lg m-4">
            <div className="px-6 py-4">
              <div className="font-bold text-xl mb-2">{plan.title}</div>
              <p className="text-gray-700 text-base whitespace-pre-line">
                {plan.description}
              </p>
              <p className="text-gray-700 text-base font-bold">
                {plan.price}
              </p>
            </div>
            <div className="px-6 py-4">
              <button
                className="bg-black text-white py-2 px-4 rounded"
                onClick={() => handleSubscribe(plan.priceId)}
              >
                Subscribe Now
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Pricing;