import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import emailjs from 'emailjs-com';
import axiosInstance from '../services/axiosInstance';

const Menu = () => {
  const [showHelpModal, setShowHelpModal] = useState(false);
  const [question, setQuestion] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [loading, setLoading] = useState(false); // New state for loading

  // Fetch user email when component mounts
  useEffect(() => {
    const fetchUserEmail = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          throw new Error('No authentication token found.');
        }

        const response = await axiosInstance.get('/user', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        setUserEmail(response.data.email); // Set the user's email
      } catch (err) {
        console.error('Error fetching user email:', err);
      }
    };

    fetchUserEmail();
  }, []);

  // Function to handle sending the email
  const handleSubmitQuestion = (e) => {
    e.preventDefault();
    setLoading(true); // Set loading to true when sending starts

    const templateParams = {
      user_email: userEmail, // Automatically captured from the user authentication
      question: question,
    };

    emailjs
      .send('service_w0pnzfj', 'template_273xm36', templateParams, 'ywHeeL1xFz31m6upy')
      .then(
        (response) => {
          console.log('SUCCESS!', response.status, response.text);
          setShowHelpModal(false); // Close the modal on successful submission
          setLoading(false); // Set loading to false when done
        },
        (error) => {
          console.log('FAILED...', error);
          setLoading(false); // Set loading to false on error
        }
      );
  };

  return (
    <>
      <nav className="bg-white border-b border-gray-200 shadow-sm py-4">
        <div className="container mx-auto px-6 lg:px-12 flex justify-between items-center">
          {/* Logo */}
          <Link to="/keywords" className="text-xl font-bold text-black" style={{ fontFamily: 'Poppins, sans-serif' }}>
            RANQIO
          </Link>

          {/* Navigation Links */}
          <div className="space-x-6">
            <Link
              to="/keywords"
              className="text-black hover:text-gray-700 transition-colors duration-200"
              style={{ fontFamily: 'Poppins, sans-serif' }}
            >
              Keyword Ideas
            </Link>
            <Link
              to="/blogposts"
              className="text-black hover:text-gray-700 transition-colors duration-200"
              style={{ fontFamily: 'Poppins, sans-serif' }}
            >
              Blog Posts
            </Link>
            <Link
              to="/how-grow"
              className="text-black hover:text-gray-700 transition-colors duration-200"
              style={{ fontFamily: 'Poppins, sans-serif' }}
            >
              Learn How to Grow
            </Link>
            <Link
              to="#"
              onClick={() => setShowHelpModal(true)}
              className="text-black hover:text-gray-700 transition-colors duration-200"
              style={{ fontFamily: 'Poppins, sans-serif' }}
            >
              Need Help?
            </Link>
            <Link
              to="/profile"
              className="text-black hover:text-gray-700 transition-colors duration-200"
              style={{ fontFamily: 'Poppins, sans-serif' }}
            >
              Profile
            </Link>
          </div>
        </div>
      </nav>

      {/* Help Modal */}
      {showHelpModal && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="relative bg-white rounded-md shadow-lg p-8 max-w-md w-full">
            <h3 className="text-xl font-semibold text-gray-900 mb-4 text-center">Need Help?</h3>
            <form onSubmit={handleSubmitQuestion}>
              <label className="block text-gray-700 mb-2">Send us your question, and we’ll respond to your email within 24 hours.</label>
              <textarea
                className="w-full border rounded-md p-2 mb-4"
                value={question}
                onChange={(e) => setQuestion(e.target.value)}
                required
              />
              <button
                type="submit"
                disabled={loading} // Disable button while loading
                className="w-full bg-black text-white py-2 px-4 rounded hover:bg-gray-800 transition"
              >
                {loading ? 'Sending...' : 'Submit'} {/* Change text based on loading */}
              </button>
            </form>
            <button
              onClick={() => setShowHelpModal(false)}
              className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
            >
              X
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default Menu;