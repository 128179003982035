import React, { useState, useEffect } from 'react';
import { fetchBlogPosts } from '../services/api'; 
import { Link } from 'react-router-dom';
import Menu from '../components/Menu';
import Footer from '../components/Footer';

// Import icons from react-icons
import { FaCalendarAlt } from 'react-icons/fa';

const BlogPostList = () => {
  const [posts, setPosts] = useState([]);
  const [isLoading, setIsLoading] = useState(true); // Loading state

  useEffect(() => {
    const fetchAllPosts = async () => {
      try {
        const result = await fetchBlogPosts();
        const sortedPosts = result.data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)); // Sort by newest first
        setPosts(sortedPosts);
      } catch (error) {
        console.error("Error fetching blog posts:", error);
      } finally {
        setIsLoading(false); // Data fetching is complete
      }
    };
    fetchAllPosts();
  }, []);  

  if (isLoading) {
    // Display loader while fetching data
    return (
      <>
        <Menu />
        <div className="flex items-center justify-center min-h-screen bg-white">
          <div className="flex flex-col items-center">
            <div className="loader mb-4"></div>
            <p className="text-lg text-gray-700">Loading blog posts...</p> {/* Reduced font size */}
          </div>
        </div>
        <Footer />
      </>
    );
  }

  if (posts.length === 0) {
    // Display message when no blog posts are available
    return (
      <>
        <Menu />
        <div className="flex items-center justify-center min-h-screen bg-white">
          <div className="flex flex-col items-center text-center px-4">
            <p className="text-lg text-gray-700 mb-4">You don't have any blog posts yet.</p> {/* Informational message */}
            <p className="text-md text-gray-600 mb-6">Start by searching for keywords to create your first blog post.</p>
            <Link
              to="/keywords" // Adjust the path based on your routing setup
              className="inline-block bg-black text-white py-2 px-4 rounded hover:bg-blue-600 transition"
            >
              Search Keywords
            </Link>
          </div>
        </div>
        <Footer />
      </>
    );
  }

  return (
    <>
      <Menu />
      <div className="container mx-auto py-16 px-6 bg-white"> {/* Reduced padding */}
        <h2 className="text-3xl font-semibold mb-12 text-center text-gray-900">
          Latest Blog Posts
        </h2>
        <ul className="space-y-12">
          {posts.map((post) => (
            <li
              key={post._id}
              className="group transition-transform transform hover:-translate-y-1 hover:shadow-lg bg-white rounded-lg shadow p-6 border border-gray-200" // Reduced padding and shadow
            >
              <Link to={`/blogpost/${post.post_id}`} className="text-black">
                <h3 className="text-xl font-bold text-gray-900 mb-4 group-hover:underline leading-tight">
                  {post.title}
                </h3>
              </Link>
              <div className="flex items-center text-gray-600 mb-6">
                <FaCalendarAlt className="h-4 w-4 mr-2" /> {/* Reduced icon size */}
                <span className="text-sm">
                  {post.createdAt
                    ? new Date(post.createdAt).toLocaleDateString('en-US', {
                        month: 'long',
                        day: 'numeric',
                        year: 'numeric',
                      })
                    : ''}
                </span>
              </div>
              <p className="text-md text-gray-700 leading-relaxed mb-6">
                {post.content.substring(0, 400)}...
              </p>
              <Link
                to={`/blogpost/${post.post_id}`}
                className="inline-block bg-black text-white py-2 px-4 rounded-full text-sm font-medium hover:bg-gray-800 transition"
              >
                Read More →
              </Link>
            </li>
          ))}
        </ul>
      </div>
      <Footer />
    </>
  );
};

export default BlogPostList;