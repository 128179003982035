// ProtectedRoute.js
import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import AuthContext from '../contexts/AuthContext';

const ProtectedRoute = ({ element }) => {
  const { token } = useContext(AuthContext);

  if (token === undefined) {
    // Authentication status is still loading
    return null; // Or a loading indicator
  }

  if (!token) {
    return <Navigate to="/signin" />;
  }

  return element;
};

export default ProtectedRoute;
