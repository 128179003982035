// src/pages/Education.js

import React from 'react';
import { Link } from 'react-router-dom';
import Menu from '../components/Menu';
import Footer from '../components/Footer';
import { FaLightbulb, FaSearch, FaEdit, FaSeedling } from 'react-icons/fa';
import howSeoWorks from '../images/howseoworks.webp';
import makeYourOwn from '../images/makeyourown.webp';
import longTail from '../images/longtail.webp';

const Education = () => {
  return (
    <>
      <Menu />
      <div className="container mx-auto py-24 px-6">
        {/* Page Header */}
        <h1 className="text-5xl font-semibold mb-12 text-center text-gray-900">
          Getting Started with Ranqio for SEO Success
        </h1>

        {/* Introduction Section */}
        <section className="mb-16">
          <p className="text-xl text-gray-700 text-center max-w-3xl mx-auto">
            Welcome to Ranqio! We're here to help you grow your business organically through effective SEO strategies, even if you're new to the world of search engine optimization.
          </p>
        </section>

        {/* How SEO Works */}
        <section className="mb-16">
          <div className="flex flex-col lg:flex-row items-center">
            <div className="lg:w-1/2 lg:pr-12 mb-8 lg:mb-0">
              <h2 className="text-3xl font-semibold mb-6 text-gray-900">
                <FaLightbulb className="inline-block text-yellow-500 mr-2" />
                How Does SEO Work?
              </h2>
              <p className="text-lg text-gray-700 mb-4">
                SEO, or Search Engine Optimization, is all about making your website more visible to people who are searching for products or services like yours on search engines (like Google). By optimizing your website and content, you can appear higher in search results, attract more visitors, and grow your business.
              </p>
              <p className="text-lg text-gray-700">
                Think of it like setting up a storefront on a busy street versus a quiet alley. The more visible you are, the more people will find you!
              </p>
            </div>
            <div className="lg:w-1/2">
              <img
                src={howSeoWorks}
                alt="Illustration of how SEO works"
                className="w-full h-auto object-cover max-w-md mx-auto rounded-lg shadow-lg"
              />
            </div>
          </div>
        </section>

        {/* Long-Tail Keywords */}
        <section className="mb-16">
          <div className="flex flex-col lg:flex-row-reverse items-center">
            <div className="lg:w-1/2 lg:pl-12 mb-8 lg:mb-0">
              <h2 className="text-3xl font-semibold mb-6 text-gray-900">
                <FaSearch className="inline-block text-blue-500 mr-2" />
                The Power of Long-Tail Keywords
              </h2>
              <p className="text-lg text-gray-700 mb-4">
                Long-tail keywords are specific phrases that people type into search engines. They usually have lower search volumes but also less competition. This makes them perfect for new or smaller websites to target.
              </p>
              <p className="text-lg text-gray-700 mb-4">
                For example, instead of targeting "project management software," you might focus on "project management software for freelancers." It's more specific and easier to rank for.
              </p>
              <p className="text-lg text-gray-700">
                Ranqio helps you find these golden opportunities so you can attract visitors who are looking exactly for what you offer.
              </p>
            </div>
            <div className="lg:w-1/2">
              <img
                src={longTail}
                alt="Illustration of long-tail keywords"
                className="w-full h-auto object-cover max-w-md mx-auto rounded-lg shadow-lg"
              />
            </div>
          </div>
        </section>

        {/* Personalizing AI-Generated Content */}
        <section className="mb-16">
          <div className="flex flex-col lg:flex-row items-center">
            <div className="lg:w-1/2 lg:pr-12 mb-8 lg:mb-0">
              <h2 className="text-3xl font-semibold mb-6 text-gray-900">
                <FaEdit className="inline-block text-green-500 mr-2" />
                Making Content Your Own
              </h2>
              <p className="text-lg text-gray-700 mb-4">
                Ranqio can generate blog posts for you, but it's important to add your personal touch. By editing and complementing the AI-generated content, you ensure it reflects your brand's voice and resonates with your audience.
              </p>
              <p className="text-lg text-gray-700 mb-4">
                Add examples, share personal experiences, or adjust the tone to match your style. This not only improves quality but also builds trust with your readers.
              </p>
              <p className="text-lg text-gray-700">
                Remember, authenticity goes a long way in connecting with your audience!
              </p>
            </div>
            <div className="lg:w-1/2">
              <img
                src={makeYourOwn}
                alt="Illustration of editing content"
                className="w-full h-auto object-cover max-w-md mx-auto rounded-lg shadow-lg"
              />
            </div>
          </div>
        </section>

        {/* Additional Tips */}
        <section className="mb-16">
          <h2 className="text-3xl font-semibold mb-8 text-center text-gray-900">
            <FaSeedling className="inline-block text-teal-500 mr-2" />
            Tips for Organic Growth with Ranqio
          </h2>
          <div className="max-w-4xl mx-auto space-y-6">
            <div className="flex items-start">
              <FaLightbulb className="text-yellow-500 h-6 w-6 mr-4 mt-1" />
              <p className="text-lg text-gray-700">
                <strong>Consistent Publishing:</strong> Regularly adding new content helps improve your visibility over time. Aim to publish blog posts frequently.
              </p>
            </div>
            <div className="flex items-start">
              <FaSearch className="text-blue-500 h-6 w-6 mr-4 mt-1" />
              <p className="text-lg text-gray-700">
                <strong>Understand Your Audience:</strong> Think about what your ideal customers are searching for and create content that answers their questions.
              </p>
            </div>
            <div className="flex items-start">
              <FaEdit className="text-green-500 h-6 w-6 mr-4 mt-1" />
              <p className="text-lg text-gray-700">
                <strong>Engaging Titles and Headings:</strong> Use clear and compelling titles that accurately reflect the content of your posts.
              </p>
            </div>
            <div className="flex items-start">
              <FaSeedling className="text-teal-500 h-6 w-6 mr-4 mt-1" />
              <p className="text-lg text-gray-700">
                <strong>Patience is Key:</strong> SEO results don't happen overnight. Keep at it, and over time you'll start to see growth in your organic traffic.
              </p>
            </div>
          </div>
        </section>

        {/* Call to Action */}
        <section className="text-center">
          <h2 className="text-3xl font-semibold mb-6 text-gray-900">
            Ready to Boost Your Organic Traffic?
          </h2>
          <p className="text-lg text-gray-700 mb-8">
            Start applying these strategies with Ranqio and watch your business grow. Remember, we're here to help every step of the way.
          </p>
          <Link
            to="/keywords"
            className="inline-block bg-black text-white py-4 px-8 rounded-full text-xl font-medium hover:bg-gray-800 transition"
          >
            Generate Ideas Now
          </Link>
        </section>
      </div>
      <Footer />
    </>
  );
};

export default Education;