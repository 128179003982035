import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { fetchBlogPostById } from '../services/api';
import ReactMarkdown from 'react-markdown'; // Import the markdown parser
import Menu from '../components/Menu';
import Footer from '../components/Footer';

// Import icons from react-icons
import { FaCalendarAlt } from 'react-icons/fa';

const BlogPostDetail = () => {
  const { id } = useParams(); // Get the post ID from the route params
  const [post, setPost] = useState(null);

  useEffect(() => {
    const fetchPost = async () => {
      try {
        const result = await fetchBlogPostById(id); // Fetch the blog post using the ID
        setPost(result); // Set the fetched post in state
      } catch (error) {
        console.error("Error fetching blog post:", error);
      }
    };
    fetchPost();
  }, [id]); // Re-run effect if the ID changes

  if (!post)
    return (
      <>
        <Menu />
        <div className="flex items-center justify-center min-h-screen bg-white">
          <div className="text-center">
            <div className="loader mb-4"></div>
            <p className="text-xl text-gray-700">Loading blog post...</p>
          </div>
        </div>
        <Footer />
      </>
    );

  return (
    <>
      <Menu />
      <div className="container mx-auto py-24 px-6 lg:px-24 bg-white">
        <h1 className="text-5xl font-bold mb-6 text-center text-gray-900 leading-tight">
          {post.title}
        </h1>
        <div className="flex items-center justify-center text-gray-600 mb-12">
          <FaCalendarAlt className="h-5 w-5 mr-2" />
          <span className="text-lg">
            {post.created_at
              ? new Date(post.created_at).toLocaleDateString('en-US', {
                  month: 'long',
                  day: 'numeric',
                  year: 'numeric',
                })
              : ''}
          </span>
        </div>
        <div className="prose prose-xl mx-auto text-gray-800 leading-relaxed">
          {/* Render the markdown content */}
          <ReactMarkdown>{post.content}</ReactMarkdown>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default BlogPostDetail;
