// Modal.js
import React from 'react';

const Modal = ({ message, onClose, onAction, actionText }) => (
  <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
    <div className="bg-white rounded-md shadow-lg p-8 max-w-sm w-full">
      <h3 className="text-xl font-semibold text-gray-900 mb-6 text-center">
        {message}
      </h3>
      <div className="flex justify-center space-x-4">
        <button
          onClick={onClose}
          className="bg-gray-300 text-black py-2 px-4 rounded-md text-sm font-medium hover:bg-gray-400 transition focus:outline-none"
        >
          Close
        </button>
        <button
          onClick={onAction}
          className="bg-black text-white py-2 px-4 rounded-md text-sm font-medium hover:bg-gray-800 transition focus:outline-none"
        >
          {actionText}
        </button>
      </div>
    </div>
  </div>
);

export default Modal;