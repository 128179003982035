// Import necessary modules and icons
import React from 'react';
import { Link } from 'react-router-dom';
import Footer from '../components/Footer';
import '../homepage.css';
import strugglingGuy from '../images/struggling-guy.webp';
import ranqioVideo from '../images/ranqio.mp4';
import HomeMenu from '../components/HomeMenu';

// Import icons from react-icons
import { FaMoneyBillWave, FaHourglassHalf, FaChartLine, FaHandsHelping, FaBullseye, FaRocket } from 'react-icons/fa';

const Home = () => {
  return (
    <>
      
      <HomeMenu />

      {/* Hero Section */}
      <div className="bg-black text-white py-24 px-6">
        <div className="container mx-auto max-w-5xl text-center">
          <h1 className="text-6xl font-bold mb-8 leading-tight">
            Affordable SEO Growth for Small Businesses
          </h1>
          <p className="text-2xl mb-12">
            Stop overspending on ads and struggling with outbound efforts.<br/>Discover easy-to-use keyword insights and content strategies using our AI solution.
          </p>
          <Link
            to="/signup"
            className="inline-block bg-white text-black py-4 px-8 rounded-full text-xl font-medium hover:bg-gray-200 transition"
            onClick={() => {
              if (typeof window !== 'undefined' && window.gtag) {
                window.gtag('event', 'signup_button_click', {
                  event_category: 'engagement',
                  event_label: 'Try it Free Button - Header',
                });
              }
            }}
          >
            Try it Free – No credit card required
          </Link>
        </div>
      </div>

      {/* Problem Section */}
      <section className="py-24 px-6 bg-white">
        <div className="container mx-auto max-w-7xl grid grid-cols-1 lg:grid-cols-2 gap-16 items-center">
          <div className="order-2 lg:order-1">
            <h2 className="text-4xl font-semibold mb-6 text-gray-900">
              Paid Traffic Is Costly, and Outbound Isn’t What It Used to Be
            </h2>
            <p className="text-xl text-gray-700 mb-8">
              Running a solo-founded business or a small agency means every dollar and hour counts. Ads are more expensive than ever, and outbound strategies that once worked are becoming less effective.
            </p>
            <div className="space-y-6 text-lg text-gray-800">
              <div className="flex items-start">
                <FaMoneyBillWave className="text-black h-6 w-6 mr-4 mt-1" />
                <p>
                  <strong>Ad costs are rising</strong> while returns are shrinking.
                </p>
              </div>
              <div className="flex items-start">
                <FaHourglassHalf className="text-black h-6 w-6 mr-4 mt-1" />
                <p>
                  <strong>Outbound efforts</strong> are time-consuming and deliver lower ROI.
                </p>
              </div>
              <div className="flex items-start">
                <FaChartLine className="text-black h-6 w-6 mr-4 mt-1" />
                <p>
                  <strong>Limited resources</strong> make traditional marketing strategies unsustainable.
                </p>
              </div>
            </div>
          </div>
          <div className="order-1 lg:order-2">
            <img
              src={strugglingGuy}
              alt="Struggling with ads and costs"
              className="w-full h-auto object-cover rounded-lg shadow-lg"
            />
          </div>
        </div>
      </section>

      {/* Solution Section */}
      <section className="py-24 px-6 bg-gray-900 text-white">
        <div className="container mx-auto max-w-7xl grid grid-cols-1 lg:grid-cols-2 gap-16 items-center">
          <div className="mb-12 lg:mb-0">
            <video
              src={ranqioVideo}
              controls
              className="w-full h-auto object-cover rounded-lg shadow-lg"
              autoPlay
              loop
              muted
            />
          </div>
          <div>
            <h2 className="text-4xl font-semibold mb-6">
              Your Cost-Effective Alternative to Expensive SEO Tools
            </h2>
            <p className="text-xl mb-8">
              We know that paying for tools like Ahrefs, SEMrush, or Moz can strain your limited budget. Our platform offers the same core benefits – keyword discovery and content strategy – without the hefty subscription fees.
            </p>
            <div className="space-y-6 text-lg">
              <div className="flex items-start">
                <FaBullseye className="text-white h-6 w-6 mr-4 mt-1" />
                <p>
                  <strong>Affordable Keyword Insights.</strong> Uncover low-competition keywords without paying for premium tools.
                </p>
              </div>
              <div className="flex items-start">
                <FaHandsHelping className="text-white h-6 w-6 mr-4 mt-1" />
                <p>
                  <strong>Content Strategy.</strong> Generate effective blog ideas and posts to engage your audience.
                </p>
              </div>
              <div className="flex items-start">
                <FaMoneyBillWave className="text-white h-6 w-6 mr-4 mt-1" />
                <p>
                  <strong>Cost-Effective.</strong> Access the features you need, without the high price of larger platforms.
                </p>
              </div>
              <div className="flex items-start">
                <FaHourglassHalf className="text-white h-6 w-6 mr-4 mt-1" />
                <p>
                  <strong>Time-Saving.</strong> Focus on growing your business while we handle your SEO needs.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Organic Growth Section */}
      <section className="py-24 px-6 bg-white">
        <div className="container mx-auto max-w-6xl text-center">
          <h2 className="text-4xl font-semibold mb-6 text-gray-900">
            Grow Your Business Organically and Sustainably
          </h2>
          <p className="text-xl text-gray-700 mb-12">
            Organic traffic is the backbone of long-term growth. By focusing on high-quality, keyword-optimized content, you’ll build authority, attract your ideal customers, and create a sustainable flow of leads – all without burning your budget.
          </p>
          <div className="space-y-6 text-lg text-left mx-auto max-w-3xl">
            <div className="flex items-start">
              <FaMoneyBillWave className="text-black h-6 w-6 mr-4 mt-1" />
              <p>
                <strong>Cost-effective.</strong> No ongoing ad spend – just content that keeps working for you.
              </p>
            </div>
            <div className="flex items-start">
              <FaRocket className="text-black h-6 w-6 mr-4 mt-1" />
              <p>
                <strong>Scalable.</strong> As your content library grows, so does your traffic.
              </p>
            </div>
            <div className="flex items-start">
              <FaHandsHelping className="text-black h-6 w-6 mr-4 mt-1" />
              <p>
                <strong>Trust-building.</strong> Organic content positions your business as an expert in your field, building trust with potential clients.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* How It Works Section */}
      <section className="py-24 px-6 bg-gray-100">
        <div className="container mx-auto max-w-5xl">
          <h2 className="text-4xl font-semibold mb-12 text-center text-gray-900">
            Easy Steps to Unlock SEO Success
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-12 text-center">
            <div>
              <div className="h-20 w-20 mx-auto mb-6 bg-black text-white rounded-full flex items-center justify-center text-2xl font-bold">
                1
              </div>
              <h3 className="text-2xl font-semibold mb-4">Identify Keyword Opportunities</h3>
              <p className="text-lg text-gray-700">
                Our tool uses your search keyword to analyze the top 10 Google results, providing you with long-tail keywords based on the content of the highest-ranking pages.
              </p>
            </div>
            <div>
              <div className="h-20 w-20 mx-auto mb-6 bg-black text-white rounded-full flex items-center justify-center text-2xl font-bold">
                2
              </div>
              <h3 className="text-2xl font-semibold mb-4">Generate Content</h3>
              <p className="text-lg text-gray-700">
                Quickly create optimized blog posts that rank using our AI tool.
              </p>
            </div>
            <div>
              <div className="h-20 w-20 mx-auto mb-6 bg-black text-white rounded-full flex items-center justify-center text-2xl font-bold">
                3
              </div>
              <h3 className="text-2xl font-semibold mb-4">Monitor & Improve</h3>
              <p className="text-lg text-gray-700">
                Track performance and continuously optimize your content for even better results.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Pricing Section */}
      <section className="py-24 px-6 bg-white">
        <div className="container mx-auto max-w-7xl text-center">
          <h2 className="text-4xl font-semibold mb-12 text-gray-900">
            Your Cost-Effective SEO Tool
          </h2>
          <div className="flex flex-col md:flex-row items-center justify-center space-y-12 md:space-y-0 md:space-x-12">
            {/* Free Trial Plan */}
            <div className="bg-gray-50 border border-gray-200 rounded-lg p-8 w-full md:w-1/3">
              <h3 className="text-2xl font-semibold mb-4 text-gray-900">
                Free Trial
              </h3>
              <p className="text-gray-700 text-base mb-6">
                Get started with our basic features.
              </p>
              <p className="text-4xl font-bold text-gray-900 mb-6">
                $0<span className="text-xl font-medium text-gray-700">/month</span>
              </p>
              <ul className="text-gray-700 text-left mb-8 space-y-4">
                <li>✅ 10 Keyword Searches</li>
                <li>✅ 5 Blog Post Creations</li>
                <li>❌ Unlimited Access</li>
                <li>❌ Premium Support</li>
              </ul>
              <Link
                to="/signup"
                className="block bg-black text-white py-3 px-6 rounded-md text-base font-medium hover:bg-gray-800 transition"
                onClick={() => {
                  if (typeof window !== 'undefined' && window.gtag) {
                    window.gtag('event', 'signup_button_click', {
                      event_category: 'engagement',
                      event_label: 'Free Trial Button - Pricing',
                    });
                  }
                }}
              >
                Start Free Trial
              </Link>
            </div>
            {/* Paid Plan */}
            <div className="bg-white border border-gray-200 rounded-lg p-8 w-full md:w-1/3 shadow-lg">
              <h3 className="text-2xl font-semibold mb-4 text-gray-900">
                Pro Plan
              </h3>
              <p className="text-gray-700 text-base mb-6">
                Unlock all features and grow faster.
              </p>
              <p className="text-4xl font-bold text-gray-900 mb-6">
                $24.9<span className="text-xl font-medium text-gray-700">/month</span>
              </p>
              <ul className="text-gray-700 text-left mb-8 space-y-4">
                <li>✅ 500 Keyword Searches</li>
                <li>✅ 100 Blog Post Creations</li>
                <li>✅ Access to All Features</li>
                <li>✅ Premium Support</li>
              </ul>
              <Link
                to="/signup"
                className="block bg-black text-white py-3 px-6 rounded-md text-base font-medium hover:bg-gray-800 transition"
                onClick={() => {
                  if (typeof window !== 'undefined' && window.gtag) {
                    window.gtag('event', 'signup_button_click', {
                      event_category: 'engagement',
                      event_label: 'Pro Plan Get Started',
                    });
                  }
                }}
              >
                Get Started
              </Link>
            </div>
          </div>
        </div>
      </section>

      {/* Testimonials Section */}
      <section className="py-24 px-6 bg-gray-900 text-white">
        <div className="container mx-auto max-w-5xl text-center">
          <h2 className="text-4xl font-semibold mb-12">
            What Our Users Say
          </h2>
          <div className="space-y-12">
            <div className="bg-gray-800 p-8 rounded-lg shadow-lg">
              <p className="text-xl italic mb-6">
                "I’ve saved so much time and money using this tool – no more spending thousands on ads. Organic traffic has become my main lead source!"
              </p>
              <p className="font-semibold">– Solo Founder, SaaS Business</p>
            </div>
            <div className="bg-gray-800 p-8 rounded-lg shadow-lg">
              <p className="text-xl italic mb-6">
                "Our small agency lacked the resources to run full-scale outbound campaigns. This platform helped us shift to organic growth, and it’s paying off!"
              </p>
              <p className="font-semibold">– Marketing Agency Owner</p>
            </div>
          </div>
        </div>
      </section>

      {/* Call to Action Section */}
      <section className="py-24 px-6 bg-white">
        <div className="container mx-auto max-w-5xl text-center">
          <h2 className="text-4xl font-semibold mb-6 text-gray-900">
            Start Growing Organically Today
          </h2>
          <p className="text-xl text-gray-700 mb-12">
            Stop relying on expensive ads and time-consuming outbound sales. Let us help you tap into the power of organic growth. Start finding keyword opportunities and creating blog content that brings in traffic – effortlessly.
          </p>
          <Link
            to="/signup"
            className="inline-block bg-black text-white py-4 px-8 rounded-full text-xl font-medium hover:bg-gray-800 transition"
            onClick={() => {
              if (typeof window !== 'undefined' && window.gtag) {
                window.gtag('event', 'signup_button_click', {
                  event_category: 'engagement',
                  event_label: 'Try Free - Bottom of the Home Page',
                });
              }
            }}
          >
            Try it Free – No credit card required
          </Link>
        </div>
      </section>

      {/* Footer */}
      <Footer />
    </>
  );
};

export default Home;